<template>
    <vx-card :title="`Purchase Receive Complete `">
        <div class="mb-6 vx-row">
            <vs-button @click="handleBack()" class="mt-2 ml-4" color="danger" icon-pack="feather"
                icon="icon-arrow-left">Back</vs-button>
        </div>
        <div class="flex md:flex-row">
            <div class="w-full pr-6 md:basis-1/2">
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Warehouse Code</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <vs-input :value="this.form.warehouse.code + ' - ' + this.form.warehouse.name" disabled
                            :readonly="true" class="w-full bg-grey-light"></vs-input>
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Supplier</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <vs-input :value="this.form.supplier.code + ' - ' + this.form.supplier.name" disabled
                            :readonly="true" class="w-full bg-grey-light"></vs-input>
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Purchase Data</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <vs-input :value="this.form.po_code" disabled :readonly="true" class="w-full bg-grey-light"></vs-input>
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>GR Document Number</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <vs-input :value="this.form.code" disabled :readonly="true" class="w-full bg-grey-light"></vs-input>
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Delivery Note Number</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <vs-input disabled v-model="form.delivery_order_number" class="w-full bg-grey-light" />
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Receive Date</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <datepicker format="yyyy-MM-dd" name="date" disabled :inline="false" v-model="form.date" class="bg-grey-light"
                            placeholder="Select Date" :cleared="() => {
                                this.form.date = null
                            }">
                        </datepicker>
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Note</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <vs-textarea v-model="form.notes" disabled class="bg-grey-light" width="100%" />
                    </div>
                </div>
            </div>
            <div class="w-full md:basis-1/2">
                <!-- <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Shipment Number</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <vs-input disabled v-model="form.shipment_number" class="w-full bg-grey-light" />
                    </div>
                </div> -->
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Attachment</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <!-- <div class="w-full vx-col sm:w-5/6 upload">
                            <vs-button class="" v-if="form.attachment && form.attachment.length > 0"
                                @click="popShow = true" color="primary" type="filled">Download</vs-button>
                            <span v-else> - No attachment - </span>
                            <vs-popup classContent="popup-example" title="Download" :active.sync="popShow">
                                <vs-list>
                                    <vs-list-item v-for="(item, index) in form.attachment" :key="index"
                                        :title="item.name" class="flex">
                                        <div class="flex">
                                            <a :href="item.url"><vs-button color="success" type="flat"
                                                    icon="file_download"></vs-button></a>
                                        </div>
                                    </vs-list-item>
                                </vs-list>
                            </vs-popup>
                        </div> -->
                        <vs-list>
                            <vs-list-item v-for="(item, index) in form.attachment" :key="index"
                                :title="item.name" class="flex">
                                <div class="flex">
                                    <a @click.stop="downloadFileAwsS3(item.path)"><vs-button color="success" type="flat"
                                            icon="file_download"></vs-button></a>
                                </div>
                            </vs-list-item>
                        </vs-list>
                    </div>
                </div>
            </div>
        </div>
        <div class="mb-6 vx-row">
            <div class="w-full">
                <table width="100%" v-if="rerendr" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th colspan="3"></th>
                            <th colspan="2">GR/Conversion</th>
                            <th colspan="4">Batch/ED Data</th>
                            <th colspan="2">Put Away</th>
                        </tr>
                        <tr>
                            <th>
                                <div>Supplier</div>
                                <div>SKU Code</div>
                            </th>
                            <th>
                                <div>Internal</div>
                                <div>SKU Code</div>
                            </th>
                            <th>
                                <div>Internal</div>
                                <div>SKU Description</div>
                            </th>
                            <th>
                                <div>HU</div>
                            </th>
                            <th>
                                <div>Qty</div>
                            </th>
                            <th>
                                <div>Qty</div>
                            </th>
                            <th>
                                <div>Batch #</div>
                            </th>
                            <th>
                                <div>Expired Date</div>
                            </th>
                            <th>
                                <div>Check Status</div>
                            </th>
                            <th>
                                <div>Qty</div>
                            </th>
                            <th>
                                <div>Suggested Warehouse</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(line, index) in form.putaway_dto" :key="index">
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="line.supplier_sku_code" :readonly="true" disabled
                                    v-if="index == 0 || (form.putaway_dto[index].purchase_order_line_id !== form.putaway_dto[index - 1].purchase_order_line_id)"
                                    class="w-full bg-grey-light"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="line.internal_sku_code" :readonly="true" disabled
                                    v-if="index == 0 || (form.putaway_dto[index].purchase_order_line_id !== form.putaway_dto[index - 1].purchase_order_line_id)"
                                    class="w-full bg-grey-light"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="line.item_name" :readonly="true" disabled
                                    v-if="index == 0 || (form.putaway_dto[index].purchase_order_line_id !== form.putaway_dto[index - 1].purchase_order_line_id)"
                                    class="w-full bg-grey-light"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="line.hu_name" :readonly="true" disabled
                                    v-if="index == 0 || (form.putaway_dto[index].conversion_key !== form.putaway_dto[index - 1].conversion_key)"
                                    class="w-full bg-grey-light"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="line.conversion_quantity" :readonly="true" disabled
                                    v-if="index == 0 || (form.putaway_dto[index].conversion_key !== form.putaway_dto[index - 1].conversion_key)"
                                    class="w-full bg-grey-light"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="line.check_quantity" :readonly="true" disabled
                                    v-if="index == 0 || (form.putaway_dto[index].purchase_receive_line_id !== form.putaway_dto[index - 1].purchase_receive_line_id)"
                                    class="w-full bg-grey-light"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="line.batch_external" :readonly="true" disabled
                                    v-if="index == 0 || (form.putaway_dto[index].purchase_receive_line_id !== form.putaway_dto[index - 1].purchase_receive_line_id)"
                                    class="w-full bg-grey-light"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <datepicker format="yyyy-MM-dd" class="bg-grey-light" name="date" :inline="false" v-model="line.expired_date"
                                    v-if="index == 0 || (form.putaway_dto[index].purchase_receive_line_id !== form.putaway_dto[index - 1].purchase_receive_line_id)"
                                    disabled placeholder="Select Date">
                                </datepicker>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="checkOptions[line.is_damaged].name" :readonly="true" disabled
                                    v-if="index == 0 || (form.putaway_dto[index].purchase_receive_line_id !== form.putaway_dto[index - 1].purchase_receive_line_id)"
                                    class="w-full bg-grey-light"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input type="number" min="0"  v-model="line.quantity" class="w-full bg-grey-light" disabled />
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="line.warehouse_area.name" class="w-full bg-grey-light" disabled />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </vx-card>
</template>

<script>
import Date from "@/components/Date.vue";
import vSelect from "vue-select";
import moment from "moment";
import Datepicker from "vuejs-datepicker";

export default {
    components: {
        Date,
        vSelect,
        Datepicker,
    },
    mounted() {
        this.id = this.$route.params.id;
        this.mode = this.$route.params.mode;
        if (this.id) {
            this.initData();
        } else {
            this.$vs.loading();
            setTimeout(this.$vs.loading.close, 3200);
        }
    },
    data() {
        return {
            rerendr:true,
            popShow: false,
            warehouseAreaOptions: [],
            checkOptions: [{
                value: 0,
                name: "Passed",
            }, {
                value: 1,
                name: "Damaged",
            }, {
                value: 2,
                name: "Expired",
            }],
            dialog: {
                save: false,
            },
            id: null,
            attachment: null,
            form: {
                id: null,
                purchase_order_id: null,
                warehouse: null,
                supplier: null,
                status: "0",
                dn_date: moment().toDate(),
                purchaseData: null,
                code: null,
                date: null,
                notes: null,
                delivery_order_number: null,
                purchase_date: null,
                shipment_number: null,
                putaway_dto: null,
            },
        }
    },
    methods: {
        handleBack() {
            this.$vs.loading();
            this.$router.push({
                name: this.$store.state.inbound.purchaseOrder.baseRouterName,
            });
            this.$vs.loading.close();
        },
        initData() {
            this.$vs.loading();
            this.$http
                .get(`${this.$store.state.inbound.purchaseOrder.baseUrlPath}/put-away/${this.id}`)
                .then((resp) => {
                    if (resp.code == 200) {
                        this.form = resp.data;
                        this.form.supplier = {
                            code: resp.data.supplier_code,
                            name: resp.data.supplier_name,
                        };
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
    }
}
</script>

<style>
td.td.vs-table--td {
    vertical-align: text-top !important;
}

.vs-input--input:disabled,
input:disabled {
    pointer-events: auto !important;
}
</style>